<template>
  <div id="searchSelect" class="is-comp" :style="{ width: width + 'px' }">
    <input
      type="text"
      class="inp-select"
      :class="{ error: tip !== '' }"
      readonly
      :value="selected.name"
      @click.stop="showOptions"
      :placeholder="initPlaceholder"
      v-if="singleChoose"
    />
    <div
      class="div-select"
      :class="{ error: tip !== '' }"
      @click.stop="showOptions"
      v-if="!singleChoose"
      ref="selectOptions"
    >
      <div v-for="(list, index) in allSelected" class="show-frame">
        <span>{{ list.name }}</span>
        <i class="sdnm-close" @click.stop="deleteChoosed(index)"></i>
      </div>
    </div>
    <i
      class="sdnm-unfold"
      v-if="selected.name === '' || selected.name === undefined"
      @click.stop="showOptions"
    ></i>
    <i
      class="sdnm-close"
      v-if="selected.name !== '' && selected.name !== undefined"
      @click.stop="cleanChoose"
    ></i>
    <div class="tip">{{ tip }}</div>
    <div
      class="search-select-options"
      v-if="optionShow"
      :style="{ top: topHeight + 'px' }"
    >
      <input
        type="text"
        class="search"
        v-model="keyword"
        :placeholder="searchPlaceholder"
        v-if="canSearch"
      />
      <i class="sdnm-search" v-if="canSearch"></i>
      <div class="options">
        <ul>
          <li
            v-for="row in filterOptions"
            id="selectItem"
            class="select-item"
            :class="{ active: row.id === selected.id }"
            @click.stop="checkOption(row)"
          >
            {{ row.name }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @module components/searchSelect
 * @desc 速商 下拉搜索选择
 * @version 0.0.1
 * @author songkexin <songkexin@rongyi.com>
 * @date 2021-08-01
 * @copyright 2021
 */
import { parse } from "../modules/utils";
export default {
  name: "searchSelect",
  props: {
    name: {
      type: String,
      default: "",
    },
    searchPlaceholder: {
      type: String,
      default: "搜索员工名称",
    },
    initPlaceholder: {
      type: String,
      default: "",
    },
    searchOptions: {
      type: Array,
      default: () => {
        return [];
      },
    },
    tip: {
      type: String,
      default: "",
    },
    singleChoose: {
      type: Boolean,
      default: true,
    },
    // 是否可以搜索
    canSearch: {
      type: Boolean,
      default: true,
    },
    chooseNum: {
      type: Number,
      default: 2,
    },
    chooseInfo: {
      type: Array,
      default: function() {
        return [];
      },
    },
    returnObj: {
      type: Boolean,
      default: false,
    },
    returnKey: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 300,
    },
  },
  data() {
    return {
      optionShow: false,
      selected: {
        id: "",
        name: "",
      },
      allSelected: [],
      keyword: "",
      topHeight: "",
    };
  },
  computed: {
    filterOptions() {
      let key = this.keyword;
      let options = parse(this.searchOptions);
      if (!this.singleChoose) {
        let selectIds = [];
        this.allSelected.filter((el) => {
          selectIds.push(el.id);
        });
        return options.filter((el) => {
          return !selectIds.includes(el.id) && el.name.indexOf(key) > -1;
        });
      } else {
        return options.filter((el) => {
          return el.name.indexOf(key) > -1;
        });
      }
    },
  },
  watch: {
    keyword(newValue, oldValue) {
      if (this.returnKey && newValue !== oldValue && newValue !== "") {
        this.$emit("back-keyword", this.keyword, this.name);
      }
    },
    optionShow(newValue, oldValue) {
      if (newValue && !oldValue) {
        window.addEventListener("click", this.windowClick);
      } else {
        window.removeEventListener("click", this.windowClick);
        this.keyword = "";
      }
    },
    chooseInfo(newValue, oldValue) {
      if (newValue.length !== 0 && oldValue !== newValue) {
        if (!this.singleChoose) {
          this.allSelected = parse(this.chooseInfo);
        } else {
          this.selected = parse(this.chooseInfo)[0];
        }
      } else if (newValue.length === 0 && this.singleChoose) {
        this.selected = {};
      } else if (newValue.length === 0 && !this.singleChoose) {
        this.allSelected = parse(this.chooseInfo);
      }
    },
  },
  mounted() {
    if (this.chooseInfo.length !== 0) {
      if (!this.singleChoose) {
        this.allSelected = parse(this.chooseInfo);
      } else {
        this.selected = parse(this.chooseInfo)[0];
      }
    }
  },
  methods: {
    /**
     * 组件界面点击事件处理
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-10-11
     */
    windowClick(event) {
      let className = event.target.className;
      if (
        className !== "search" &&
        className !== "sdnm-search" &&
        className !== "choose-more"
      ) {
        if (this.optionShow) {
          this.optionShow = false;
          this.keyword = "";
        }
      }
    },
    /**
     * 组件界面点击事件处理
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-10-11
     */
    selectClick() {
      this.$nextTick(() => {
        console.log(this.$refs.selectOptions.clientHeight);
        if (this.topHeight !== this.$refs.selectOptions.clientHeight + 6) {
          this.topHeight = this.$refs.selectOptions.clientHeight + 6;
        }
      });
    },
    /**
     * 回调函数，返回选择的数据
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-10-11
     */
    checkOption(row) {
      if (this.singleChoose) {
        this.selected = row;
        if (!this.returnObj) {
          this.$emit("set-select", row.id, this.name);
        } else {
          this.$emit("sdnm-set-select", row, this.name);
        }
        this.optionShow = false;
      } else {
        if (this.allSelected.length < this.chooseNum) {
          this.allSelected.push(row);
          let ids = parse(this.allSelected);
          if (!this.returnObj) {
            ids.map((el) => {
              return el.id;
            });
            this.$emit("sdnm-search-select-more", ids, this.name);
          } else {
            this.$emit("sdnm-search-select-more", ids, this.name);
          }
        }
      }
    },
    /**
     * 显示下拉框
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-10-11
     */
    showOptions() {
      this.optionShow = true;
      window.addEventListener("click", this.windowClick);
      if (!this.singleChoose && this.filterOptions.length !== 0) {
        this.$nextTick(() => {
          document
            .getElementById("selectItem")
            .addEventListener("click", this.selectClick);
        });
      }
    },
    /**
     * 删除选择数据后，返回选择的数据
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-10-11
     */
    deleteChoosed(index) {
      this.allSelected.splice(index, 1);
      let ids = parse(this.allSelected);
      ids.map((el) => {
        return el.id;
      });
      this.$emit("sdnm-search-select-more", ids, this.name);
    },
    /**
     * 清空数据
     * @author songkexin <songkexin@rongyi.com>
     * @date   2021-10-11
     */
    cleanChoose() {
      this.checkOption({
        id: "",
        name: "",
      });
    },
  },
};
</script>

<style lang="less">
@import (reference) "../less/common";
#searchSelect {
  white-space: pre; // 之前是为了兼容火狐浏览器样式具体可查看git提交日志
  min-height: 30px;
  .mgr(8px);
  .pos-r();
  .fs(0);
  .inp-select {
    width: 100%;
  }
  .tip {
    .fc(12px, #f84445);
    .pos-a(1, 23px, 0);
  }
  .search-select-options {
    .pos-a(1, 34px, 0);
    right: 0;
    .bgc(#ffffff);
    box-shadow: 0 4px 6px 0 #aab1ba;
    .brdr(4px);
    height: 200px;
    .mgt(-4px);
    .pd(4px);
    > .search {
      width: 99.6%;
    }
    > .options {
      .wh(100%, 158px);
      overflow-y: auto;
      > ul {
        list-style: none;
        .pd(0);
        > li {
          .fc(12px, #666666);
          height: 30px;
          line-height: 30px;
          .pdl(10px);
          .cs();
          &:hover,
          &.active {
            .bgc(#e9f2f1);
          }
        }
      }
    }
  }
  .div-select {
    min-height: 30px;
    width: 100%;
    .pdl(4px);
    .pdt(3px);
    .pdr(30px);
    .dib();
    border: 1px solid #a0b9dc;
    .brdr(2px);
    white-space: normal;
    + i {
      .dib();
      .fc(12px, #a0b0dc);
      .mgl(-25px);
      .cs();
      .hlh(30px);
      .va-t();
    }
    .show-frame {
      white-space: nowrap;
      .hlh(20px);
      .va-t();
      .pdl(5px);
      .pdr(5px);
      .dib();
      .bgc(#eceff3);
      .brdr(26px);
      .fc(12px, #525e6e);
      .mgl(4px);
      .mgb(4px);
      > i {
        .cs();
        font-size: 12px;
      }
    }
    &.error {
      .brd(1px, solid, #f84445);
    }
  }
  .show-placeholder {
    .dib();
    .fc(12px, #525e6e);
    .pd(4px 8px 4px 8px);
    .mgl(4px);
    .mgb(4px);
  }
}
</style>

<template>
  <div class="wrapper" ref="page">
    <ctheader></ctheader>
    <div class="content">
      <div class="table-head">
        <div class="left">
          <!-- <select class="w150" v-model="tableSet.marketId" @change="changeHq">
					<option value="">全部市场</option>
					<option :value="row.id" v-for="row in hqList">{{row.market_name}}</option>
				</select> -->
          <searchSelect
            class="mgl-8"
            :searchOptions="hqList"
            searchPlaceholder="搜索市场"
            name="prosearch"
            :returnObj="true"
            :chooseInfo="chooseInfo"
            @sdnm-set-select="changeHq"
          >
          </searchSelect>
          <input
            type="text"
            class="search"
            placeholder="搜索档口名称、uuid"
            @keypress.enter="getList()"
            v-model="tableSet.keyword"
          />
          <i class="sdnm-icon sdnm-search" @click="getList()"></i>
        </div>
      </div>
      <div class="table-scroll">
        <table class="table" width="100%">
          <thead>
            <tr>
              <th v-for="field in fields" :class="field.class">
                {{ field.name }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in dataList">
              <td class="pw15">
                <span class="table-span">{{ row.market_name }}</span>
              </td>
              <td class="pw15">
                <span class="table-span">{{ row.uuid }}</span>
              </td>

              <!-- TODO -->
              <td class="pw15">
                <span class="table-span">{{ row.os_version }}</span>
              </td>
              <!-- TODO -->
              <td class="pw15">
                <span class="table-span">
                  {{ row.pos_type == 2 ? "安卓" : "-" }}
                </span>
              </td>
              <td class="pw15">
                <span class="table-span">{{ row.orgz_name }}</span>
              </td>
              <td class="pw10">
                <span v-if="row.is_online === 1">启用</span>
                <span v-if="row.is_online === 2">离线</span>
              </td>
              <td class="operation pw15">
                <a @click="view(row)">查看日志</a>
              </td>
            </tr>
            <tr v-if="dataList.length === 0">
              <td class="empty" :colspan="fields.length">没有相关记录</td>
            </tr>
          </tbody>
        </table>
      </div>
      <pagination
        :start="tableSet.start"
        :total="tableSet.total"
        :page-length="tableSet.length"
        :pageCallback="getList"
      ></pagination>
    </div>
  </div>
</template>

<script>
/**
 * @file pages/index.vue
 * @desc 下载日志
 * @version 0.1.0
 * @author songkexin <songkexin@rongyi.com>
 * @since 2021-04-09
 * @copyright 2021
 */
/* jshint esversion: 6 */
import { mapGetters, mapActions } from "vuex";
// import router from '@/router';
import * as URL from "../../modules/URLs";
import APIs from "../../modules/APIs";
import { apiPost } from "../../modules/utils";
// import ctheader from '@@/ctheader';
// import pagination from '@@/pagination';
// import searchSelect from '@@/searchSelect';
import router from "../../router";
import ctheader from "../../components/ctheader";
import pagination from "../../components/pagination";
import searchSelect from "../../components/searchSelect";

const getters = mapGetters([]);

const actions = mapActions(["setAlert"]);

const fields = [
  { name: "市场名称", class: "pw15" },
  { name: "posUUID", class: "pw15" },
  { name: "品牌型号", class: "pw15" },
  { name: "系统类型", class: "pw15" },
  { name: "绑定档口名称", class: "pw15" },
  { name: "状态", class: "pw10" },
  { name: "操作", class: "pw15" },
];

/**
 * @module index.vue
 * @desc this is module index.vue
 * @author songkexin <songkexin@rongyi.com>
 * @since 2021-04-09
 * @copyright 2021
 */
export default {
  data() {
    return {
      fields,
      dataList: [],
      pageInfo: {},
      tableSet: {
        start: 0,
        length: 10,
        total: 0,
        keyword: "",
        hq_code: "",
        marketId: "",
      },
      hqList: [],
      chooseInfo: [{ id: "", name: "全部市场" }],
    };
  },
  components: {
    ctheader,
    pagination,
    searchSelect,
  },
  computed: {
    ...getters,
  },
  methods: {
    // changeHq(e){
    // 	console.log(e);
    // 	let id = e.target.value;
    // 	let arr = this.hqList.filter(el => el.id + '' === id);
    // 	this.tableSet.hq_code = arr.length > 0 ? arr[0].hq_code : '';
    // 	this.getList();
    // },
    changeHq(row) {
      console.log(row);
      this.tableSet.hq_code = row.hq_code;
      this.tableSet.marketId = row.id;
      this.getList();
    },
    getHqList() {
      let $this = this;
      apiPost({
        url: APIs.COMPANY,
        data: {
          action: "market.list",
          start: 0,
          length: 9999,
        },
        success(res) {
          if (res.code === "0") {
            $this.hqList = res.data.map((el) => {
              return {
                id: el.id,
                hq_code: el.hq_code,
                name: el.market_name,
              };
            });
            $this.hqList.unshift({ id: "", name: "全部市场" });
          } else {
            $this.hqList = [];
          }
        },
        error(res) {
          $this.hqList = [];
        },
      });
    },
    getList(index = 0) {
      let $this = this;
      this.tableSet.start = index;
      apiPost({
        url: APIs.POS,
        data: {
          action: "pos.list",
          hq_code: this.tableSet.hq_code,
          keyword: this.tableSet.keyword,
          id: this.tableSet.marketId,
          start: this.tableSet.start,
          length: this.tableSet.length,
        },
        success(res) {
          if (res.code === "0") {
            $this.dataList = res.data;
            $this.tableSet.total = res.total;
          } else {
            $this.dataList = [];
            $this.tableSet.total = 0;
          }
        },
        error(res) {
          $this.dataList = [];
          $this.tableSet.total = 0;
          $this.setAlert({ msg: "获取数据失败" });
        },
      });
    },
    view(row) {
      router.push(`${URL.VIEW_DEVICE}?hq_code=${row.hq_code}&id=${row.id}`);
    },
    ...actions,
  },
  mounted() {
    this.getHqList();
    this.getList();
  },
};
</script>
0

<style lang="less">
.table-span {
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: 40px;
  min-width: 40px;
  white-space: normal;
  word-break: break-all;
  text-overflow: ellipsis;
  line-height: 23px;
}
</style>
